import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "KOPKAR TOYOTA";
export const API_BASE_URL = env.API;
export const APP_PREFIX_PATH = "/anggota";
export const AUTH_PREFIX_PATH = "/auth";
export const ADMIN_PREFIX_PATH = "/admin";
export const MANAGER_PREFIX_PATH = "/manager";
export const DEALER_PREFIX_PATH = "/dealer";
export const VENDOR_PREFIX_PATH = "/vendor";
export const VENDOR_NON_ANGGOTA_PREFIX_PATH = "/vendor-non-anggota";
export const KASIR_PREFIX_PATH = "/kasir";
export const KITCHEN_PREFIX_PATH = "/kitchen";
export const HRD_PREFIX_PATH = "/hrd";
export const POS_PREFIX_PATH = "pos/admin/";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};

export const checkManagerSunter = () => {
  const name = localStorage.getItem('name')

  if(name){
    if(name.search('Karawang') > 0){
      window.location.assign('manager/approval-pinjaman')
    }
  }
}