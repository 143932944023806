import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  // {
  //   key: "verifikasi-user",
  //   path: `${ADMIN_PREFIX_PATH}/verifikasi-user`,
  //   title: "Verifikasi User",
  //   icon: DatabaseOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "list-dealer",
    path: `${ADMIN_PREFIX_PATH}/list-dealer`,
    title: "Data Dealer",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-karyawan",
    path: `${ADMIN_PREFIX_PATH}/master-karyawan`,
    title: "Data Karyawan",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-bank",
    path: `${ADMIN_PREFIX_PATH}/master-bank`,
    title: "Data Bank",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-barang",
    path: `${ADMIN_PREFIX_PATH}/master-barang`,
    title: "Data Barang",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
