import { Row, Space, Button } from "antd";

const ButtonFormHelper = ({
  callback,
  okText = "Simpan",
  cancelText = "Batal",
  justify = "end",
  color = "#04990A",
  loading = false,
    callbackSubmit=null
}) => {
  return (
    <Row justify={justify} gutter={4}>
      <Space>
        <Button
          size="small"
          style={{
            border: `1px solid ${color}`,
            backgroundColor: "white",
            color: color,
            fontWeight: "bold",
          }}
          type="danger"
          htmlType="button"
          onClick={callback}
        >
          {cancelText}
        </Button>
        <Button
          className="ant-custom"
          loading={loading}
          size="small"
          style={{
            fontWeight: "bold",
            border: `1px solid ${color}`,
            backgroundColor: color,
          }}
          htmlType={callbackSubmit!==null?"button":"submit"}
          onClick={(e)=>{
              if(callbackSubmit!==null) callbackSubmit()
          }}
          type="danger"

        >
          {okText}
        </Button>
      </Space>
    </Row>
  );
};

export default ButtonFormHelper;
