import {
    PlusSquareOutlined,
    FileTextOutlined
  } from "@ant-design/icons";
  import { ADMIN_PREFIX_PATH } from "configs/AppConfig";
  
  const dashBoardNavTree = [
    {
      key: "tambah-artikel",
      path: `${ADMIN_PREFIX_PATH}/tambah-artikel`,
      title: "Tambah Artikel",
      icon: PlusSquareOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "daftar-artikel",
      path: `${ADMIN_PREFIX_PATH}/daftar-artikel`,
      title: "Daftar Artikel",
      icon: FileTextOutlined,
      breadcrumb: false,
      submenu: [],
    },
  ];
  
  const navigationConfig = [...dashBoardNavTree];
  
  export default navigationConfig;
  