import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "manage_pengajuan",
    path: `${ADMIN_PREFIX_PATH}/home`,
    title: "Manage Pengajuan",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationAllConfig = [...dashBoardNavTree];

export default navigationAllConfig;
