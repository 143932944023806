// const prod = {
//   API_ENDPOINT_URL: "https://jsonplaceholder.typicode.com",
//   API: "http://192.168.10.242",
//   // WS: "kopkar-api.tmmintam.com/socket.io",
  
//   WS: "kopkar-ws.tmmintam.com",
// };

export const env = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
  API: process.env.REACT_APP_API,
  // WS: "kopkar-api.tmmintam.com/socket.io",
  
  WS: process.env.REACT_APP_WS,
};
