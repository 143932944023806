import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import navigationVendorNonAnggotaConfig from "configs/NavigationVendorNonAnggota";
export const VendorNonAnggotaViews = () => {
  const [stnk, setStnk] = useState(false);
  const [spk, setSpk] = useState(false);
  const getData = () => {
    axios
      .get(env.API + "/user/auth/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const data = res.data.data.role.permissions;
        data.forEach((d) => {
          if (d == "MANAGE_STNK") {
            setStnk(true);
          }
        });
      })
      .catch((e) => {});
  };
  useEffect(() => {
    // setError(true);

    // if (!data.length) {
    //  showToken(setTokenFound);
    getData();
    // let newArr = [
    //   {
    //     key: "data-vendor/detail",
    //     path: `${ADMIN_PREFIX_PATH}/data_vendor/:id`,
    //   },
    // ];

    // newArr.map((res) => {
    //   navigationCafeConfig.push(res);
    // });
    // }
  }, []);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {navigationVendorNonAnggotaConfig.map((res, i) => {
          return (
            <Route
              key={i}
              path={res.path}
              component={lazy(() => import("./" + res.key + "/index"))}
            />
          );
        })}
        <Route
          path={"/vendor-non-anggota/pengiriman_barang/:id"}
          component={lazy(() => import("./pengiriman-barang/detail"))}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(VendorNonAnggotaViews);
