import {
  InboxOutlined,
  CarOutlined,
  BookOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { VENDOR_NON_ANGGOTA_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${VENDOR_NON_ANGGOTA_PREFIX_PATH}/dashboard`,
    title: "Dashboard",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "spk",
    path: `${VENDOR_NON_ANGGOTA_PREFIX_PATH}/spk`,
    title: "SPK",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pengiriman-barang",
    path: `${VENDOR_NON_ANGGOTA_PREFIX_PATH}/pengiriman-barang`,
    title: "Pengiriman Barang",
    icon: CarOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "penerimaan-barang",
    path: `${VENDOR_NON_ANGGOTA_PREFIX_PATH}/penerimaan-barang`,
    title: "Penerimaan Barang",
    icon: InboxOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "invoice",
    path: `${VENDOR_NON_ANGGOTA_PREFIX_PATH}/invoice`,
    title: "Invoice",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [],
  },
  
];

const navigationVendorNonAnggotaConfig = [...dashBoardNavTree];

export default navigationVendorNonAnggotaConfig;
