import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  CheckCircleOutlined,
  CodeSandboxOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, MANAGER_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${MANAGER_PREFIX_PATH}/approval-pinjaman`,
    title: "Approval Pinjaman",
    icon: CheckCircleOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "home",
  //   path: `${MANAGER_PREFIX_PATH}/approval-quotation`,
  //   title: "Approval Quotation",
  //   icon: CheckCircleOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "pinjaman-barang",
    path: `${MANAGER_PREFIX_PATH}/pinjaman-barang`,
    title: "Pinjaman Barang",
    icon: CodeSandboxOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pinjaman-stnk",
    path: `${MANAGER_PREFIX_PATH}/pinjaman-stnk`,
    title: "Pinjaman STNK",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [],
  }
];

const name = localStorage.getItem('name')

if(name){
  if(name.search('Sunter') > 0){

    dashBoardNavTree.push(
    {
      key: "pelunasan",
      path: `${MANAGER_PREFIX_PATH}/pelunasan`,
      title: "Pelunasan",
      icon: CodeSandboxOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "pengaturan-budget",
      path: `${MANAGER_PREFIX_PATH}/pengaturan-budget`,
      title: "Pengaturan Budget",
      icon: SlidersOutlined,
      breadcrumb: false,
      submenu: [],
    }
    )
  }
}


const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
