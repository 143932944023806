import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import { LINK } from "helpers/string_helper";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/admin/login`}
          component={lazy(() => import(`./authentication/login-admin`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/login-1`}
          component={lazy(() => import(`./authentication/login-1`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/login-2`}
          component={lazy(() => import(`./authentication/login-2`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/register-1`}
          component={lazy(() => import(`./authentication/register-1`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/register`}
          component={lazy(() => import(`./authentication/register-2`))}
        />
          <Route
          path={`${AUTH_PREFIX_PATH}/register-3`}
          component={lazy(() => import(`./authentication/register-3`))}
        />
        <Route
          path={LINK.linkForgot}
          component={lazy(() => import(`./authentication/forgot-password`))}
        />
        <Route
          path={LINK.linkReset}
          component={lazy(() => import(`./authentication/new-password`))}
        />

        <Route
          path={`${AUTH_PREFIX_PATH}/error-1`}
          component={lazy(() => import(`./errors/error-page-1`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/error-2`}
          component={lazy(() => import(`./errors/error-page-2`))}
        />
        <Route
          path={LINK.linkVerify}
          component={lazy(() => import(`./authentication/verif`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/otp-password`}
          component={lazy(() => import(`./authentication/otp-password`))}
        />
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${AUTH_PREFIX_PATH}/login`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
